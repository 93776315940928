import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import {Link, navigate} from 'gatsby'

import BackgroundIndex from '../images/index-background.png';
import BackgroundTorn from '../images/torn-bg.png';
import IndexImage from '../images/index-large.png';
import IndexSmall from '../images/index-small.png';
import QrIndex from '../images/qr-index.png';
import QrBlack from '../images/qr-black.png';
import AppMockup from '../images/app-mockup.png'; 

import Women from '../images/2women.png'; 
import MultiHand from '../images/multi-hand.png'; 
import TwoWomenBack from '../images/2women-back.png';
// SVG
import Playdate from '../images/svg/playdate.svg';
import ArrowLink from '../images/svg/arrow-link.svg';
import BlackArrow from '../images/svg/black-arrow.svg';
import IndexLine from '../images/svg/index-line.svg';
import IndexLineBlack from '../images/svg/index-line-black.svg';
import VerticalLine from '../images/svg/vertical-line-white.svg';

// Blog
import Article1 from '../images/blog/article1.png';
import Article2 from '../images/blog/article2.png';
import Article3 from '../images/blog/article3.png';

const Index = ({ data }) => {
    console.log("Index data : ", data);
    return (
        <main>
        <div className="bg-gray-900 lg:overflow-hidden relative" style={{  
            backgroundImage: "url(" + BackgroundIndex + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
            }}>
          <div className="mx-auto">
            <div className="md:grid md:grid-cols-2">
              <div className="mx-auto sm:max-w-2xl md:flex md:items-center md:px-0 md:text-left">
                <div className="md:py-24 md:pl-28	md:pr-28">
                  <h1 className="text-comorant lg:text-7xl text-6xl tracking-tight text-white mb-6 pt-44 md:pt-0 md:px-0 px-14">
                    <span className="block font-semibold uppercase">DISCOVER</span>
                    <span className="block"><span className='italic'>YOUR </span><span className='font-semibold	'>BLISS</span></span>
                  </h1>
                  <p className="text-white text-lg lg:px-0 px-14">
                  The premier social app for swinging and open relationships. Connect with other members for new friendships and play.
                  </p>
                  <div className='relative flex flex-col block md:hidden text-center'>
                    <div className='mx-auto block my-10'>
                      <img src={VerticalLine} alt="" />
                    </div>
                    <div className='qr-index-mobile mx-auto block'>
                      <img src={QrIndex} alt="Index QR" />
                    </div>
                  </div>
                  <div className='block md:hidden w-full'>
                    <img className='w-full' src={IndexSmall} alt="Index Image" />
                  </div>
                </div>
              </div>
              <div className="lg:relative m-0 hidden md:block">
                <div className="mx-auto max-w-md sm:max-w-2xl lg:max-w-none lg:px-0">
                  {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                  <img className='float-right' src={IndexImage} alt="Index Image" />
                </div>
              </div>
            </div>
          </div>
          <div className='line-index hidden md:block'>
            <img src={IndexLine} alt="" />
          </div>
          <div className='qr-index hidden md:block'>
            <img src={QrIndex} alt="Index QR" />
          </div>
        </div>

        {/* EXPLORE LFIESTYLE SECTION */}

        <div className="bg-light py-24 sm:py-32 lg:py-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="sm:text-center">
              <div className='inline-block mb-6'><img src={Playdate} alt="Playdate Icon" /></div>
              <h2 className="text-comorant text-black text-5xl mb-5 uppercase">Explore the lifestyle </h2>
              <p className="mx-auto text-base	mb-12">
                Discover new desires such as swinging, BDSM, NTR, voyeurism, and much more. Find new friends who share the same desires.
              </p>
            </div>
            <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
              <img src={AppMockup} alt="" />
            </div>
          </div>
        </div>

        {/* DESIGN BY SEX POSITIVE SECTION */}

        <div className="bg-bown-400 py-24 sm:py-32 lg:py-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="lg:grid lg:grid grid-cols-3">
              
              <div className="mx-auto col-span-2 text-center">
                <div className="">
                  <h1 className="text-comorant lg:text-8xl text-5xl tracking-tight text-black mb-6">
                    <span className="block font-semibold	">DESIGNED</span>
                    <span className="block"><span className='font-semibold'>BY </span><span className='italic'>SEX-POSITIVE </span></span>
                    <span className='block font-semibold	text-right'>PEOPLE</span>
                  </h1>
                  <div className='flex flex-col-reverse	lg:flex-row'>
                    <img className='max-w-sm' src={MultiHand} alt="Hand touching" />
                    <div className=''>
                      <p className="text-black text-base w-60 text-left mb-8">
                      Learn all about who we are and why we started Club Mella.
                      </p>
                      <div className='text-left'>
                        <Link to='/about' className="text-blush text-xl text-left text-comorant">Read more</Link>
                        <img src={ArrowLink} alt="Hand touching" />
                      </div>
                      
                    </div>
                  </div>

                </div>
              </div>
              <div className="hidden lg:block">
                <div className='float-right'>
                  <img src={Women} alt="Index Image" />
                </div>
                
              </div>

            </div>
          </div>
        </div>

        {/* EVENT SECTION */}

        <div className="bg-light py-24 sm:py-32 lg:py-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="lg:grid lg:grid grid-cols-3">

              <div className='text-comorant text-left text-5xl uppercase'>
                Events
              </div>
              <div className="mx-auto col-span-2 text-center mb-20">
                <div className='text-base text-left mb-8'>
                  We’ve been hosting sex-positive parties in cities around Taiwan since 2016. Check out our latest events here.
                </div>
                <div className='text-left flex items-center'>
                  <Link to='/about' className="text-blush text-xl text-left text-comorant">View All</Link>
                  <img src={ArrowLink} alt="Hand touching" />
                </div>
              </div>
            </div>

            <div className="lg:grid lg:grid grid-cols-3 flex flex-col-reverse mb-32">

              <div className=''>
              <img src={TwoWomenBack} alt="Hand touching" />
              </div>
              <div className="col-span-2">

                <div className='flex justify-between items-center	py-4 border-bottom mb-12'>
                  <div className='flex justify-between items-center'>
                    <div className='lg:text-base text-xs	'>Jan 18</div>
                    <div className='lg:text-3xl text-lg	 ml-12'>Swingers Sex Party</div>
                  </div>
                  <div className='text-base'>
                    <img src={BlackArrow}/>
                    </div>
                </div>

                <div className='flex justify-between items-center	py-4 border-bottom mb-12 opacity-40'>
                  <div className='flex justify-between items-center'>
                    <div className='lg:text-base text-xs	'>Jan 20</div>
                    <div className='lg:text-3xl text-lg	 ml-12'>Sauna Eveving Party</div>
                  </div>
                  <div className='text-base'>
                    <img src={BlackArrow}/>
                    </div>
                </div>

                <div className='flex justify-between items-center	py-4 border-bottom mb-12 opacity-40'>
                  <div className='flex justify-between items-center'>
                    <div className='lg:text-base text-xs	'>Jan 24</div>
                    <div className='lg:text-3xl text-lg	 ml-12'>Back to Paradise</div>
                  </div>
                  <div className='text-base'>
                    <img src={BlackArrow}/>
                    </div>
                </div>


              </div>
            </div>

            <div className="mb-20 relative">
              <div className='text-comorant text-left text-5xl uppercase'>
                Recent Articles
              </div>
              <div className='line-index-black'>
                <img src={IndexLineBlack} alt="" />
              </div>
            </div>

            <div className='flex flex-col items-center lg:flex-row'>

              <div className='event-card bg-bown-light p-4 mb-8'>
                <div className='mb-4'>
                  <img src={Article1}/>
                </div>
                <div className='text-3xl text-comorant'>
                  Interview with event organizer Peter
                </div>
              </div>

              <div className='event-card bg-bown-light p-4 lg:mx-4 lg:mt-40 mx-0 mt-0 mb-8'>
                <div className='mb-4'>
                  <img src={Article2}/>
                </div>
                <div className='text-3xl text-comorant'>
                  Meet J&S: an interview  with an ENM couple in Taipei
                </div>
              </div>

              <div className='event-card bg-bown-light p-4 lg:mt-20 mt-0 mb-8'>
                <div className='mb-4'>
                  <img src={Article3}/>
                </div>
                <div className='text-3xl text-comorant'>
                  What is Ethical Non-Monogamy
                </div>
              </div>

            </div>

          </div>
        </div>

        {/* DISCOVER EMOTION SECTION */}

        <div className="bg-light py-24 sm:py-32 lg:py-40 pt-72" style={{  
            backgroundImage: "url(" + BackgroundTorn + ")",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="sm:text-center mb-16">
              <h2 className="text-comorant text-black text-5xl mb-5 uppercase">Discover new emotion </h2>
              <p className="mx-auto text-base	mb-12">
                Discover new desires such as swinging, BDSM, NTR, voyeurism, and much more. Find new friends who share the same desires.
              </p>
            </div>

            <div className='flex relative'>
              <div className='blur-bg-card'></div>
              <div className='container-qr-code mx-auto p-4'>
                <div className='text-center text-blush text-lg mb-4'>
                  Scan QR Code
                </div>
                <img className='mx-auto' src={QrBlack} alt="Index QR" />
              </div>
            </div>

          </div>
        </div>

      </main>
    )
}


export default Index